import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(CSSPlugin,Draggable);

export default class HpGalleryDrag {
    constructor() {
        this.container = document.querySelector('#popin-gallery');
        if (this.container === null) return;
        //this.slidersMobile = document.querySelectorAll('#popin-gallery');
        Draggable.create("#svg-to-drag", {
            type:"x,y",
            edgeResistance:0.65,
            inertia:true,
            allowEventDefault: true,
            onClick: function() {
                if(window.innerWidth <= 1024 && document.querySelector('.infoHotSpotActive')){
                    document.querySelector('.infoHotSpotActive').classList.remove('infoHotSpotActive')
                }
            },
        });

        this.openPopin = document.querySelector('#view-more-gallery');
        this.openPopin.addEventListener('click', () => {

            this.fadeIn(this.container, 300);
            /*if(window.innerWidth <= 1024) {
                const textH = this.container.querySelector('.left-inner').offsetHeight;
                const contH = this.container.offsetHeight;
                const mapH = contH - textH;
                this.container.querySelector('.right').style.height = ""+(mapH-48)+"px";
            }*/
        })
        this.closePopin = document.querySelector('#close-popin');
        this.closePopin.addEventListener('click', () => {
            this.fadeOut(this.container, 300);
        });
        this.hostSpotsBtns = document.querySelectorAll('.hotspot');
        if(window.innerWidth > 1024){
            this.hotSpotsDesk();
        }else{
            this.hotSpotsMobile();
        }


    }


    hotSpotsDesk(){
        this.hostSpotsBtns.forEach(function (el) {
            el.addEventListener('mouseenter', () => {
                let target = document.querySelector('.infoHotSpot'+el.dataset.index+'');

                let x = el.getBoundingClientRect().left - 34;
                let y = el.getBoundingClientRect().top - 34;
                gsap.set(target,{top:y, left:x});
                target.classList.add('infoHotSpotActive');
            });

            el.addEventListener('mouseleave', () => {
                let target = document.querySelectorAll('.infoHotSpot'+el.dataset.index+'');
                target[0].classList.remove('infoHotSpotActive')
            });
        });
    }

    hotSpotsMobile(){
        this.hostSpotsBtns.forEach(function (el) {
            el.addEventListener('click', () => {
                if(document.querySelector('.infoHotSpotActive')){
                    document.querySelector('.infoHotSpotActive').classList.remove('infoHotSpotActive')
                }
                let target = document.querySelector('.infoHotSpot'+el.dataset.index+'');
                target.classList.add('infoHotSpotActive');
            });
        });
    }


    fadeIn(el, time) {
        el.style.display = "block"
        el.style.opacity = 0;

        var last = +new Date();
        var tick = function() {
            el.style.opacity = +el.style.opacity + (new Date() - last) / time;
            last = +new Date();

            if (+el.style.opacity < 1) {
                (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
            }
        };

        tick();
    }

    fadeOut(el, time) {
        var last = +new Date();
        var tick = function() {
            el.style.opacity = +el.style.opacity - (new Date() - last) / time;
            last = +new Date();

            if (+el.style.opacity > 0) {
                (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
            }else{
                el.style.display = "none"
            }
        };

        tick();
    }

}

import { gsap } from 'gsap';

export default class HpHomeSlider {
  constructor() {
    this.container = document.querySelector('.hp-home-slider');
    if (this.container === null) return;
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    document.querySelector('body').classList.add('loading')

    this.initIntro();

  }

  initIntro() {
    let fSize = '';
    const that = this;
    const tl = gsap.timeline();
    const svg = this.container;
    const marquee = svg.querySelector('.textes-container');
    gsap.set(svg.querySelector('#illu-30'), { scale: 4, alpha: 1, transformOrigin: '50% 50%' })
    tl.to(marquee, {
      alpha: 1,
      duration: 1,
      onComplete() {
        gsap.to(marquee, {
          top: '0px',
          yPercent: 0,
          xPercentgs: -50,
          duration: 1,
        });
        if (window.innerWidth > 1025) {
          fSize = '2vw';
        } else {
          fSize = '6vw';
        }
        gsap.to(marquee.querySelectorAll('h3'), {
          fontSize: fSize,
          duration: 1,
        });
        gsap.to(svg.querySelector('.illu-container '), {
          alpha: 1,
          duration: 1,
        })
        gsap.to('#cp-menu', {
          y: 0,
          duration: 0.5,
          delay: 0.5,
        })
      },
    });

    tl.to(svg.querySelector('#illu-30'), {
      scale: 1,
      transformOrigin: '50% 50%',
      duration: 2,
      ease: 'back.out(1.3)',
    });
    tl.to(svg.querySelectorAll('path'), { alpha: 1 });
    tl.to(svg.querySelector('.line-bg'), {
      width: '100%',
      onComplete() {
          that.anim_illu();
        document.querySelector('body').classList.remove('loading');

      },
    })
  }
  anim_illu(){
      gsap.to('#Flower-1',{rotate:'10deg', transformOrigin:"center bottom", repeat:-1, yoyo:true, duration:1.6});
      gsap.to('#Flower-2',{rotate:'10deg', repeat:-1, yoyo:true, duration:1});
      gsap.to('#Flower-3',{rotate:'10deg', transformOrigin:"right center", repeat:-1, yoyo:true, duration:1});

      gsap.to('#Pince-1',{rotate:'10deg', transformOrigin:"right bottom", repeat:-1, yoyo:true, duration:1});
      gsap.to('#Pince-2',{rotate:'-10deg', transformOrigin:"right bottom", repeat:-1, yoyo:true, duration:1});

      gsap.to('#Layer_30',{rotate:'360deg', transformOrigin:"center center", repeat:-1, yoyo:true, duration:3});
      gsap.to('#Layer_31',{rotate:'360deg', transformOrigin:"center center", repeat:-1, yoyo:true, duration:3});

      gsap.fromTo('#Soleil',{y:'10px'},{y:'0%', transformOrigin:"center bottom", repeat:-1, yoyo:true, duration:2});

      gsap.to('#Bateau',{y:'3px', x:"-10px", rotate:"5deg",transformOrigin:"center center", repeat:-1, yoyo:true, duration:3 });


      gsap.to('#Drop-2', {y:'-5px', x:'10px', rotate:'5deg', alpha:0, duration:1,repeat:-1})

  }
}

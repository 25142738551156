import Swiper from 'swiper';
import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

export default class HpGallerySlider {
  constructor() {
    this.container = document.querySelector('.swiper-gallery-slider');
    if (this.container === null) return;

    this.sliders = document.querySelectorAll('.swiper-gallery-slider');

    Array.from(this.sliders).forEach((slider) => {
      this.initSlider(slider)
    });

      this.slidersGallery = document.querySelectorAll('.swiper-gallery');

      Array.from(this.slidersGallery).forEach((slider) => {
          this.initGallerySwiper(slider)
      });

  }

  initSlider(slider) {
    const swiper = new Swiper(slider, {
      speed: 200,
      loop: false,
      spaceBetween: 0,
      slidesPerView: 5.5,
      centeredSlides: false,
      initialSlide: 0,
      breakpoints: {
        0: {
          slidesPerView: 1.3,
        },

        961: {
          slidesPerView: 4,
        },
      },
    });

  }


    initGallerySwiper(slider){
        const swiperG = new Swiper(slider, {
            speed: 200,
            loop: true,
            init:false,
            spaceBetween: 0,
            slidesPerView: 3,
            centeredSlides: true,
            simulateTouch:false,
            navigation: {
                nextEl: '.swiper-gallery-nav-next',
                prevEl: '.swiper-gallery-nav-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },

                961: {
                    slidesPerView: 3,
                    centeredSlides: true,
                },
            },
        });
        swiperG.on('init',function(){

            var childs = document.getElementById('swiper-gallery-black').children; //returns a HTMLCollection

            for (var i = 0; i < childs.length; i++) { // iterate over it
                childs[i].onclick = function () {   // attach event listener individually
                    if(this.classList.contains("swiper-slide-next")){
                        swiperG.slideNext();
                    }
                    if(this.classList.contains("swiper-slide-prev")){
                        swiperG.slidePrev();
                    }
                }
            }


        });

        swiperG.init();

        swiperG.on('transitionEnd', function(){
            const rIndex = swiperG.realIndex;
            document.querySelector('.current-slider-gallery').innerHTML = rIndex+1;
        });



    }
}

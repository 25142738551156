import { gsap } from 'gsap';
import DrawSvg from '../libs/DrawSVGPlugin.min';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSvg, ScrollTrigger);
export default class Anims {
  constructor() {


      window.addEventListener('load', (event) =>{

          this.ArrayArrowsHover = document.querySelectorAll('.arrow-hover');
          this.ArrayLinksAnim = document.querySelectorAll('.link_anim');
          this.ArrowsHover();
          this.LinksAnimHover();
          gsap.set('.hover-svg,.anim-svg path', { drawSVG: 0 });
          gsap.set('.link_anim path', { drawSVG: 0 });

          this.initSvgAnimation();
          this.initScaleAnimation();
          this.initFromLeftAnimation();
          this.initFromRightAnimation();
          this.initFromBottomAnimation();
          if (window.innerWidth > 1024) {
              this.initToTopScrollAnimation();
          }

      });
   // this.initAnimGrape();
  }

  ArrowsHover() {
    Array.from(this.ArrayArrowsHover).forEach((arrow) => {
      arrow.addEventListener('mouseenter', () => {
        gsap.to(arrow.querySelector('.hover-svg'), { duration: 0.5, drawSVG: '100%' });
      });
      arrow.addEventListener('mouseleave', () => {
        gsap.to(arrow.querySelector('.hover-svg'), { duration: 0.5, drawSVG: '0%' });
      });
    });
  }

    LinksAnimHover() {
    Array.from(this.ArrayLinksAnim).forEach((item) => {
        item.addEventListener('mouseenter', () => {
        gsap.to(item.querySelector('.hover-svg'), { duration: 0.3, drawSVG: '100%' });
      });
        item.addEventListener('mouseleave', () => {
        gsap.to(item.querySelector('.hover-svg'), { duration: 0.3, drawSVG: '0%' });
      });
    });
  }

  initSvgAnimation() {
    const AnimsSvg = gsap.utils.toArray('.anim-svg');

    AnimsSvg.forEach((anim) => {
      gsap.to(anim.querySelector('path'), {
        drawSVG: '100%',
        scrollTrigger: {
          trigger: anim,
          start: 'center 80%',
          toggleActions: 'play pause play reverse',

        },
      })
    });
  }

  initScaleAnimation() {
    const scrollScalesEl = gsap.utils.toArray('.scroll-scale');

    scrollScalesEl.forEach((anim) => {
      gsap.to(anim, {
        scale: 1,
        scrollTrigger: {
          trigger: anim,
          start: 'center center',
          toggleActions: 'play pause play reverse',

        },
      })
    });
  }

  initFromLeftAnimation() {
    const scrollLeftEl = gsap.utils.toArray('.scroll-show-left');

    scrollLeftEl.forEach((anim) => {
      gsap.to(anim, {
        x: 0,
        scrollTrigger: {
          trigger: anim,
          start: 'top 60%',
          toggleActions: 'play pause play reverse',

        },
      })
    });
  }
    initFromBottomAnimation() {
        const scrollTopEl = gsap.utils.toArray('.scroll-show-bottom');

        scrollTopEl.forEach((anim) => {
            gsap.to(anim, {
                y: 0,
                alpha:1,
                scrollTrigger: {
                    trigger: anim,
                    start: 'top 80%',
                    toggleActions: 'play pause play reverse',

                },
            })
        });
    }

  initFromRightAnimation() {
    const scrollLeftEl = gsap.utils.toArray('.scroll-show-right');

    scrollLeftEl.forEach((anim) => {
      gsap.to(anim, {
        x: 0,
        scrollTrigger: {
          trigger: anim,
          start: 'top 60%',
          toggleActions: 'play pause play reverse',

        },
      })
    });
  }

  initAnimGrape() {
    const grapeSvg = document.querySelector('#grapeSvg');

    gsap.to('#grape-seed', {
      rotate: '10deg',
      repeat: 4,
      transformOrigin: 'center top',
      duration: 0.2,
      yoyo: true,
      scrollTrigger: {
        trigger: grapeSvg,
        start: 'top 60%',
        toggleActions: 'play pause play reverse',
      },
      onComplete() {
        gsap.to('#grape-seed', {
          rotate: '45deg',
          y: '520px',
          transformOrigin: 'center center',
          duration: 2,
        })
      },
    })
  }

  initToTopScrollAnimation() {
    let tl = gsap.timeline();
    const items = document.querySelectorAll('.scroll-to-top');
    items.forEach((item) => {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          toggleActions: 'play none play none',

          scrub: true,
          start: 'top 100%',
          end: 'bottom 70%',
        },
      });
      tl.fromTo(item,
        { y: '15%' },
        { y: '-15%' });
    });
  }
}

// import Intro from './modules/intro';
import Anims from './global/anims';
import Header from './global/header'
import HpHomeSlider from './modules/hp/hp-home-slider';
import cpHpHistorySlider from './modules/hp/cp-hp-history-slider';
import HpGalleryDrag from './modules/hp/hp-gallery-drag';
import HpGallerySlider from './modules/hp/hp-gallery-slider';
import HpProductsList from './modules/hp/hp-products-list';

document.addEventListener('DOMContentLoaded', () => {
  // new Intro();
  new Anims();
  new Header();
  new HpHomeSlider();
  new cpHpHistorySlider();
    new HpGalleryDrag();
  new HpGallerySlider();
  new HpProductsList();
});





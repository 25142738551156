import Swiper, { Navigation } from 'swiper';
import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

Swiper.use([Navigation]);
gsap.registerPlugin(CSSPlugin);

export default class cpHpHistorySlider {
  constructor() {
    this.container = document.querySelector('.swiper-slider-history');
    if (this.container === null) return;

    this.sliders = document.querySelectorAll('.swiper-slider-history');

    Array.from(this.sliders).forEach((slider) => {
      this.initSlider(slider)
    });
  }

  initSlider(slider) {
    const nav = slider.closest('.block-slider-history').querySelector('.swiper-slider-history-nav');
    const swiper = new Swiper(slider, {
      speed: 0,
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1.33,
      centeredSlides: true,
      initialSlide: 1,
      simulateTouch: false,
      breakpoints: {

        0: {
            simulateTouch: false,
          slidesPerView: 1,
          spaceBetween: 0,
          initialSlide: 0,
          centeredSlides: false,
        },

        961: {
          slidesPerView: 1.33,
          initialSlide: 1,
          centeredSlides: true,
        },
      },
    });

    gsap.set(slider.querySelector('.swiper-slide-active').querySelector('.slide-text').querySelector('.text'), { alpha: 1, y: 0 })
    swiper.on('transitionEnd', () => this.animTransition(slider, swiper, 'show'))

    this.nextHistoryBtns = nav.getElementsByClassName('swiper-slider-history-nav-next');
    this.PrevHistoryBtns = nav.getElementsByClassName('swiper-slider-history-nav-prev');

    Array.from(this.nextHistoryBtns).forEach((button) => {
      button.addEventListener('click', () => {
        this.animTransition(slider, swiper, 'hide', 'next');
      });
    });
    Array.from(this.PrevHistoryBtns).forEach((button) => {
      button.addEventListener('click', () => {
        this.animTransition(slider, swiper, 'hide', 'prev');
      });
    });
  }

  animTransition(slider, swiper, type, direction) {
    const slides = slider.querySelectorAll('.swiper-slide');
    let i = 0;

    if (type === 'hide') {
      this.classesNames = 'slide-imgs-item transition';
      gsap.to(slider.querySelector('.swiper-slide-active').querySelector('.slide-text').querySelector('.text'), { y: '-30px', alpha: 0 })
    } else {
      this.classesNames = 'slide-imgs-item';
    }

    slides.forEach((slide) => {
      const Imgs = slide.querySelectorAll('.slide-imgs-item');
      let d = 0;
      Imgs.forEach((img) => {
        gsap.set(img, {
          className: this.classesNames,
          delay: d,
          onComplete() {
            if (type === 'hide') {
              i += 1;
              if (i === slides.length * 2) {
                setTimeout(() => {
                  if (direction) {
                    if (direction === 'next') {
                      swiper.slideNext();
                    } else {
                      swiper.slidePrev();
                    }
                  }
                }, 1000)
              }
            } else {
              const currentSlide = swiper.realIndex + 1;
              slider.closest('#history').querySelector('.current-slide').textContent = currentSlide;
              gsap.to(slider.querySelector('.swiper-slide-active').querySelector('.slide-text').querySelector('.text'), { y: '0px', alpha: 1 })
            }
          },
        });
        d += 0.3;
      });
    });
  }
}

export default class Header {
  constructor() {
    this.menuScroll();
    this.toggleMenu();
  }

  menuScroll() {
    const header = document.querySelector('#cp-menu')
    let lastScrollTop = 0;
    /*window.addEventListener('scroll', (e) => {
      const st = window.scrollY;
      if (st > 100) {
        if (st > lastScrollTop) {
          // downscroll code

          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
      }

      lastScrollTop = st;
    })*/
  }

  toggleMenu() {
    const btn = document.querySelector('.burger-mobile');
    const menu = document.querySelector('#cp-menu')
    btn.addEventListener('click', () => {
      menu.classList.toggle('active-mobile');
    });
  }
}

import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

export default class HpProductsList {
  constructor() {
    this.container = document.querySelector('.block-product-list');
    if (this.container === null) return;
    this.slidersMobile = document.querySelectorAll('.block-product-list');
    Array.from(this.slidersMobile).forEach((productList) => {
        //this.initProductHover(productList);
      this.initSliderMobile(productList)
    });

  }

  initProductHover(productList){

      const productsItems = productList.querySelectorAll('.product');
      const productsItemsContent = productList.querySelectorAll('.product-content-item');
      productsItems.forEach((product) => {
          const c = product.getAttribute('data-txt');
          let cible = productList.querySelector('.'+c+'');
          product.addEventListener('mouseenter', function(){
              cible.classList.add('active');
          })
          product.addEventListener('mouseleave', function(){
              cible.classList.remove('active');
          })
      });
      productsItemsContent.forEach((product) => {
          product.addEventListener('mouseenter', function(){
              product.classList.add('active');
          })
          product.addEventListener('mouseleave', function(){
              product.classList.remove('active');
          })
      })

  }

  initSliderMobile(productList) {
    let i = 1;
    const slider = productList.querySelector('.products-c');
    const sliderItems = productList.querySelectorAll('.product');
    const navPrev = productList.querySelector('.nav').querySelector('.prev');
    const navNext = productList.querySelector('.nav').querySelector('.next');

    navNext.addEventListener('click', () => {
      if (i === 1) {
        navPrev.style.display = 'block';
      }
      if (i < sliderItems.length) {
        gsap.to(slider, { x: '-=60%' });
        sliderItems[i - 1].classList.remove('active')
        sliderItems[i].classList.add('active')
        i += 1;
      }
      if (i === sliderItems.length) {
        navNext.style.display = 'none';
      }
    })
    navPrev.addEventListener('click', () => {
      if (i > 1) {
        gsap.to(slider, { x: '+=60%' });
        i -= 1;
        sliderItems[i].classList.remove('active')
        sliderItems[i - 1].classList.add('active')
      }
      if (i === 1) {
        navPrev.style.display = 'none';
        navNext.style.display = 'block';
      }
    })
  }
}
